<template>
  <div>
    <ManageSite />
  </div>
</template>
<script>
import ManageSite from "@/components/Site/ManageSite";
export default {
  components: {
    ManageSite,
  },
  created() {},
};
</script>
c
